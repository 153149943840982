import { useState } from 'react';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

export function usePhotoGallery() {
  const [photos, setPhotos] = useState<UserPhoto[]>([]);

  // This doesn't work as expected?
  const pickImages = async () => {
    const galleryPhotosPicked = await Camera.pickImages({
      quality: 10,
      limit: 2
    });
    console.log(galleryPhotosPicked);
    for (const photo of galleryPhotosPicked.photos) {
      const imageResponse = await fetch(photo.webPath);
      // const pth = photo.webPath as unknown;
      // const photo_data = await fetch(<URL>imageResponse) as any;
      const photo_blob = await imageResponse.blob();
      const photo_bin = await photo_blob.arrayBuffer();
      const photo_b64 = btoa(new Uint8Array(photo_bin)
        .reduce((data, byte) => data + String.fromCharCode(byte), '')
      );
  
      const fileName = new Date().getTime() + '.jpeg';
      const newPhotos = [
        {
          filepath: fileName,
          webviewPath: photo.webPath,
          b64_data: photo_b64,
        },
        ...photos,
      ];
      // console.log('Photo picked:' + photos.length)

      if(photos.length < 6){
        setPhotos(newPhotos);
      }
    }
  }

  const takePhoto = async () => {
    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 10,
    });
    const pth = photo.webPath as unknown;
    const photo_data = await fetch(<URL>pth) as any;
    const photo_blob = await photo_data.blob();
    const photo_bin = await photo_blob.arrayBuffer();
    const photo_b64 = btoa(new Uint8Array(photo_bin)
      .reduce((data, byte) => data + String.fromCharCode(byte), '')
    );

    const fileName = new Date().getTime() + '.jpeg';
    const newPhotos = [
      {
        filepath: fileName,
        webviewPath: photo.webPath,
        b64_data: photo_b64,
      },
      ...photos,
    ];
    // console.log('Photo taken:' + photos.length)

    if(photos.length < 6){
      setPhotos(newPhotos);
    }

  };

  const deletePhoto = (index: any) => {
    console.log('delete')
    const newPhotos = photos.splice(index - 1);
    setPhotos(newPhotos);
  }

  return {
    photos,
    deletePhoto,
    takePhoto,
    pickImages
  };
}

export interface UserPhoto {
  filepath: string;
  webviewPath?: string;
}