import './login.css';
import { useState } from 'react';
import {
  IonContent,
  IonPage,
  IonToast,
} from '@ionic/react';

import { useHistory } from "react-router-dom";

import NumPad from 'react-numpad';
import VersionNumber from '../components/VersionNumber';


const Login = () => {
  const displayRule = value => value; // remove leading zeros

  const history = useHistory();
  const [showToast, setShowToast] = useState(false);

  const EnterForm = () => {    
    history.replace("/form");
  }
  
  const SetPincode = (pin) => {
  // Voor de groep aannemers graag het wachtwoord 232578
  // Voor de groep Verhagen VvE Beheer het wachtwoord: 002110
    // debugger;
    switch (pin) {
      case '150129':
        window.localStorage.setItem('pin_role', 'admin');
        EnterForm();    
        break;
      case '232578':
        window.localStorage.setItem('pin_role', 'aannemer');
        EnterForm();    
        break;
      case '002110':
      case '02110':
      case '2110':  
        window.localStorage.setItem('pin_role', 'Verhagen VvE Beheer');
        EnterForm();    
        break;
    
      default:
        window.localStorage.setItem('pin_role', 'incorrect');
        setShowToast(true);
        break;
    }
  } 
  

  return (
    <IonPage>
      <IonContent padding className="container">
          <div className="full-height">
            {/* <IonText className="title-main rel-z1" color="primary">Log in</IonText> */}

            <img className="logo rel-z1" src={'/assets/verhagen_logo.png'} height="189px" width="194px" alt="logo" />

            {/* <IonText color="primary">Voer uw pincode in</IonText> */}
            <NumPad.Number
                onChange={(value) => { SetPincode(value)}}
                placeholder={'Inloggen met VvE code'}
                decimal={0}
                max={999999}
                displayRule={displayRule}
                negative={false}
            />

            <img className="skyline" src={'/assets/skyline.png'} width="100%" height="auto" alt="skyline" />
            <VersionNumber></VersionNumber>
        </div>
      </IonContent>
      <IonToast
          color="danger"
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message="Code onjuist, probeer opnieuw"
          duration={3000}
          cssClass="error-toast"
        />
    </IonPage>
  );
};

export default Login;
