import { IonText } from '@ionic/react';


const VersionNumber = () => {
    return (
        <IonText style={{
            "padding": "5px",
            "margin-top": "auto",
            "display": "block",
            "text-align": "center",
            "position": "relative",
            "font-size": "12px",
        }}>
            V2.11
        </IonText>
    );
  };
  
  export default VersionNumber;