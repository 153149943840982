import { useState, useRef, useEffect } from 'react';
import './form.css';
// import SignaturePad from 'react-signature-canvas';
import { useHistory } from "react-router-dom";
import useLocalStorage from "use-local-storage";
import {
  IonContent,
  IonPage,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonList,
  IonItem,
  IonInput,
  IonDatetime,
  IonPopover,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonLabel,
  IonText,
  IonToast,
} from '@ionic/react';
import {
  calendar,
  camera,
  imagesOutline,
  close,
} from 'ionicons/icons';
import { usePhotoGallery } from '../hooks/usePhotoGallery';
import { Controller, useForm } from 'react-hook-form';

import { format, parseISO } from 'date-fns';
import VersionNumber from '../components/VersionNumber';


const Form = () => {
  const { control, handleSubmit } = useForm();
  const { photos, pickImages,takePhoto,  deletePhoto } = usePhotoGallery();
  const [subject, setSubject] = useState();
  const [state, setState] = useState();
  const [error, setFormError] = useState();
  const [loading, setLoading] = useState(false);

  const defaultDate =  new Date().toISOString();
  const [selectedDate, setSelectedDate] = useState(defaultDate);
  const [parsedDate, setParsedDate] = useState(defaultDate);

  const history = useHistory();

  const [mail, setMail] = useLocalStorage("mail", "");

  const parseDate = (date) => {
      setSelectedDate(date);
      const formattedString = format(parseISO(date), 'dd-MM-yyyy');
      setParsedDate(formattedString);      
  }


  const EnterLogin = () => {    
    history.replace("/login");
  }

  // const api_server = 'http://localhost:8080';
  const api_server = 'https://api-dot-v-vve-beheer.ew.r.appspot.com';  
  const endpoint = 'api/report';
  const user_role = window.localStorage.getItem('pin_role');

  useEffect(() => {
    parseDate(defaultDate);
  }, []);


  const saveForm = (data) => {
    // if (formIsValid()) {
      // const signature = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
      const payload = {
        datum: parsedDate, 
        onderwerp: subject, 
        staat_van_onderhoud: state, 
        email: mail,
        ...data, 
        photos: photos, 
        // signature: signature 
      };
      setLoading(true);
      fetch(`${api_server}/${endpoint}`, {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
        .then(response => response.json())
        .then(payload => {
          setLoading(false);
          console.log('Success:', payload);
          setShowToast(true);
        })
        .catch((error) => {
          setLoading(false);
          setFormError(error);
          console.error('Error:', error);
        });
    // };
  }

  const [showToast, setShowToast] = useState(false);

  // const sigCanvas = useRef({});

  // const formIsValid = () => {
  //   if (!sigCanvas.current.isEmpty()) {
  //     return true;
  //   } else {
  //     console.log('form validation failed');
  //     if (sigCanvas.current.isEmpty()) {
  //       setFormError('Oeps. Zorg ervoor dat je een handtekening plaatst en probeer het nogmaals.');
  //     } else {
  //       setFormError('Oeps. Er is een fout opgetreden tijdens het opsturen van je schaderapport. Zorg ervoor dat je alle velden hebt ingevuld en probeer het nogmaals.')
  //     }

  //     return false;
  //   }
  // }

  const removePhoto = (index) => {
    deletePhoto(index);
    console.log(photos);
  } 



  // const save = () =>
  //   console.log(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));

  return (
    <IonPage>
      <IonContent className="container">
        <form onSubmit={handleSubmit(saveForm)} className="form">
          <IonList className="container">
            <IonItem className="ion-no-padding" lines="none">
              <IonText className="title-main" color="primary">Formulier ({user_role})</IonText>
            </IonItem>

            <IonItem lines="none">
              <Controller
                control={control}
                name="naam"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <IonInput className="input" required="true" type="name" placeholder="Naam*" onIonChange={onChange}></IonInput>
                )}
              />
            </IonItem>

            <IonItem lines="none">
              <IonGrid className="marg-bot no-pad-sides">
                <IonRow>
                  <IonButton className="button-input" id="date-button">
                    <IonText>Datum: {parsedDate}</IonText><IonIcon size="small" icon={calendar}></IonIcon>
                  </IonButton>
                  <IonPopover trigger="date-button">
                    <IonDatetime name="datum"
                      presentation="date"
                      locale="nl-NL"
                      value={selectedDate}
                      onIonChange={(e) => parseDate(e.target.value)}
                      showDefaultButtons>
                    </IonDatetime>
                  </IonPopover>
                </IonRow>
              </IonGrid>
            </IonItem>

            <IonItem lines="none">
              <Controller
                control={control}
                name="adres"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <IonInput className="input" type="text" name="adres" required="true" placeholder="Straat + huisnummer*" onIonChange={onChange}></IonInput>
                )}
              />
            </IonItem>

            <IonItem lines="none">
              <Controller
                control={control}
                name="postcode"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <IonInput className="input" type="text" name="postcode" required="false" placeholder="Postcode" onIonChange={onChange}></IonInput>
                )}
              />
            </IonItem>

            <IonItem lines="none">
              <Controller
                control={control}
                name="woonplaats"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <IonInput className="input" type="text" name="city" required="true" placeholder="Plaats*" onIonChange={onChange}></IonInput>
                )}
              />
            </IonItem>

            <IonItem lines="none">
              <IonSelect className="input select-button" name="onderwerp" required="true" value={subject} placeholder="Onderwerp selecteren*" onIonChange={e => setSubject(e.detail.value)}>
                <IonSelectOption value="dak">Dak</IonSelectOption>
                <IonSelectOption value="gevels">Gevels</IonSelectOption>
                <IonSelectOption value="riolering">Riolering</IonSelectOption>
                <IonSelectOption value="betonwerk">Betonwerk</IonSelectOption>
                <IonSelectOption value="kozijnen">Kozijnen</IonSelectOption>
                <IonSelectOption value="binnen het appartment">In het appartement</IonSelectOption>
                <IonSelectOption value="waterschade">Waterschade</IonSelectOption>
              </IonSelect>
            </IonItem>

            <IonItem lines="none">
              {/* <IonLabel>Staat van onderhoud</IonLabel> */}
              <IonSelect className="input select-button" name="staat_van_onderhoud" value={state} required="true" placeholder="Staat van onderhoud selecteren*" onIonChange={e => setState(e.detail.value)}>
                <IonSelectOption value="new">Nieuwbouw</IonSelectOption>
                <IonSelectOption value="goed">Goed</IonSelectOption>
                <IonSelectOption value="redelijk">Redelijk</IonSelectOption>
                <IonSelectOption value="matig">Matig</IonSelectOption>
                <IonSelectOption value="slecht">Slecht</IonSelectOption>
                <IonSelectOption value="zeer slecht">Zeer slecht</IonSelectOption>
                <IonSelectOption value="noodzakelijk bij uitvoeren">Noodzakelijk bij uitvoeren</IonSelectOption>
                <IonSelectOption value="nader te bepalen">Nader te bepalen</IonSelectOption>
                <IonSelectOption value="niet kunnen inspecteren">Niet kunnen inspecteren</IonSelectOption>
              </IonSelect>
            </IonItem>

            { photos.length > 0 && <IonGrid>
              <IonRow>
                {photos.map((photo, index) => (
                  <IonCol size="6" key={index} onClick={ () => removePhoto(index)}>
                    <IonImg src={photo.webviewPath} />
                  </IonCol>
                ))}
              </IonRow>
            </IonGrid> }

            <IonItem lines="none">
              <IonGrid className="no-pad-sides">
                { photos.length < 6 && <IonRow>
                  <IonText className="title-label" color="primary">Voeg hier maximaal 6 foto's toe</IonText>
                </IonRow> }
                { photos.length < 6 && <IonRow>                  
                    <IonButton className="button-input" onClick={() => takePhoto()}>
                      <IonText>Foto toevoegen</IonText><IonIcon size="medium" icon={imagesOutline}></IonIcon>
                    </IonButton>
                  {/* { photos.length < 6 && <IonCol size="6" className="ion-no-padding ion-padding-start">
                      <IonButton className="button-input" onClick={() => pickImages()}>
                        <IonText>Fotogallerij</IonText><IonIcon size="medium" icon={imagesOutline}></IonIcon>
                      </IonButton>
                  </IonCol> } */}
                  {/* { photos.length > 7 && <IonCol size="12" className="ion-no-padding ion-padding-start">
                      <IonButton className="button-input" onClick={() => deletePhoto()}>
                        <IonText>Verwijder laatste foto</IonText><IonIcon size="medium" icon={imagesOutline}></IonIcon>
                      </IonButton>
                  </IonCol>} */}
                </IonRow> }
                { photos.length > 5 && <IonRow>
                    <IonText className="title-label" color="danger">Maximaal aantal foto's bereikt!</IonText>
                  </IonRow>}
              </IonGrid>
            </IonItem>

            <IonItem lines="none">
              <Controller
                control={control}
                name="emailfld"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <IonInput className="input" type="email" name="email" required="false" placeholder="E-mailadres" value={mail} onIonChange={(e) => setMail(e.target.value)}></IonInput>
                )}
              />
            </IonItem>

            <IonItem lines="none">
              <Controller
                control={control}
                name="omschrijving"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <IonTextarea auto-grow className="input" rows={1} type="text" required="true" name="description" placeholder="Omschrijving van het probleem*" onIonChange={onChange}></IonTextarea>
                )}
              />
            </IonItem>

            {/* <IonItem className="relative" lines="none">
              <div className="sigCanvas">
                <div className="flex-column">
                <IonLabel className="sign-placeholder">Handtekening*</IonLabel>
                <IonIcon class="icon-clear" size="large" icon={close} onClick={sigCanvas.current.clear}></IonIcon>
                </div>
                <SignaturePad ref={sigCanvas} penColor='red' canvasProps={{ height: 200, width: 450 }} />
                <IonButton className="button-clear" onClick={sigCanvas.current.clear}>Verwijder</IonButton>
              </div>
            </IonItem> */}

            {error && <IonItem lines="none">
              <ion-text color="danger">{error}</ion-text>
            </IonItem>}

            <IonItem lines="none">
              <IonGrid className="marg-bot">
                <IonRow className="marg-bot">
                  <IonButton disabled={loading} className="button-submit" type="submit">
                    {!loading ? 'Versturen' : 'Bezig met versturen...'}
                  </IonButton>
                </IonRow>
              </IonGrid>
            </IonItem>

          </IonList>
        </form>
        <VersionNumber></VersionNumber>
        <IonToast
          color="success"
          isOpen={showToast}
          onDidDismiss={() => EnterLogin()}
          message="Succesvol verstuurd!"
          duration={3000}
          cssClass="succes-toast"
        />
      </IonContent>
    </IonPage>
  );
};

export default Form;
